<template>
   <div class="comMain">
      <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="openLuyin" :subtitle="title" v-if="!detail"></count-down>
      <div class="content">
        <div class="m-b-40 text-bold font-22">{{questionDetail.name}}</div>
        <div class="font-20">
          {{questionDetail.sub_name}}
        </div>
      </div>
      <!-- 答题后 -->
      <div class="daan" v-if="detail">
        <div class="font-22 text-bold m-b-20">我的答案</div>
        <audio :src="questionDetail.user_answer" ref="audio"
           :autoplay="false"
           controls
           controlslist="nodownload  noremoteplayback noplaybackrate" 
           preload="metadata">
          您的浏览器不支持音频播放
      </audio>
      </div>

      <!-- 录制音频 -->
      <make-audio :show="show" :time="questionDetail.audio_time" @close="closeAudio" @confirm="submitAudio" :key="timer" ref="makeAudio" v-if="show"></make-audio>
   </div>
</template>
<script>
export default {
  props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      }
    },
   data() {
      return {
        answer: '',
        title: "录制答案",
        //录音部分
        show: false, 
        timer: '',
      }
   },
   watch: {
      questionDetail(newVal, oldVal) {
        // console.log("666")
        if(this.$refs.countDownComponent) {
          this.$nextTick(() => {
            this.$refs.countDownComponent.initData();
          })
        }
      }
    },
   methods: {
      openLuyin() {
        this.show = true;
        this.timer = new Date().getTime();
      },
      closeAudio() {
        this.show = false;
      },
      submitAudio(e) {
        this.answer = e.voiceUrl;
        this.show = false;

        // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)

        this.$emit("submitAnswer",{
          answer: this.answer,
          supletime: supletime
        })
      },
   },
}
</script>
<style  lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    margin: 40px auto;
  }
  // 我的答案
  .daan {
    padding-top: 30px;
    padding-bottom: 30px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
