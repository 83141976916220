<template>
   <div class="comMain">
      <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="confirm" v-if="!detail" ></count-down>
      <div class="content">
          <div class="font-22 text-bold">{{questionDetail.name}}</div>
          <!-- 句首 -->
            <div class="desc" v-if="questionDetail.location==-1">
              <!-- 未答题 -->
              <block v-for="(item,index) in kong_value_list" :key="index">
                <block v-if="!detail">
                  <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="item.look" v-if="item.look">
                  <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.content" :disabled="item.look" v-else>
                </block>
                <block v-else>
                  <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="true" v-if="item.look">
                 <input type="text" class="kong" :class="item.is_true?'dui':'err'" v-model="item.write" :disabled="true" v-else>
                </block>
              </block>
              <span class="m-l-10 font-20">
                {{questionDetail.front_content}}{{questionDetail.later_content}}
              </span>
            </div>
            <!-- 句中 -->
            <div class="desc" v-if="questionDetail.location==0">
              <span class="indent m-r-10">
                {{questionDetail.front_content}}
              </span>
              <!-- 未答题 -->
              <block v-for="(item,index) in kong_value_list" :key="index" v-if="!is_submit">
                <block v-if="!detail">
                  <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="item.look" v-if="item.look">
                  <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.content" :disabled="item.look" v-else>
                </block>
                <block v-else>
                  <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="true" v-if="item.look">
                  <input type="text" class="kong" :class="item.is_true?'dui':'err'" v-model="item.write" :disabled="true" v-else>
                </block>
              </block>
              <span class="m-l-10">
                {{questionDetail.later_content}}
                </span>
            </div>
            <!-- 句尾 -->
            <div class="desc" v-if="questionDetail.location==1">
              <span class="indent m-r-10">
                  {{questionDetail.front_content}}{{questionDetail.later_content}}
              </span>
              <!-- 未答题 -->
              <block v-for="(item,index) in kong_value_list" :key="index" v-if="!is_submit">
                <block v-if="!detail">
                  <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="item.look" v-if="item.look">
                  <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.content" :disabled="item.look" v-else>
                </block>
                <block v-else>
                  <input type="text" class="kong" :class="item.look?'dis':''" v-model="item.answer"  :disabled="true" v-if="item.look">
                  <input type="text" class="kong" :class="item.is_true?'dui':'err'" v-model="item.write" :disabled="true" v-else>
                </block>
              </block>
            </div>
      </div>

   </div>
</template>
<script>
export default {
    props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      }
    },
   data() {
      return {
        correct: false,   //答案
        answer: '',
        is_submit: false, //是否提交答案
        kong_value_list: [],
        
      }
   },
   watch: {
      questionDetail(newVal, oldVal) {
        // console.log("666")
        if(this.$refs.countDownComponent) {
          this.$nextTick(() => {
            this.$refs.countDownComponent.initData();
          })
        }
      }
    },
   mounted() {
      this.calcData()
    },
    methods: {
      calcData() {
        if(this.questionDetail.topic_type_name == "补全单词") {
          this.kong_value_list = [];
          if(!this.detail) {
            this.questionDetail.answer.forEach((v,k) => {
              this.kong_value_list.push({
                  answer: v.answer,
                  look: v.look,
                  content: v.look?v.answer:'',
                  correct: false
              })
            })
          }else {
            // this.questionDetail.answer.forEach((v,k) => {
            //   this.kong_value_list.push({
            //       answer: v.answer,
            //       look: v.look,
            //       content: v.answer,
            //       correct: false
            //   })
            // })
            this.kong_value_list = this.questionDetail.user_answer;
          }
        
          // console.log("kong_value_list",this.kong_value_list)
        }
      },
      confirm() {
        //获取填写的答案
        var answer = [];
        
        for(var i=0;i<this.kong_value_list.length;i++) {
          if(!this.kong_value_list[i].content) {
            this.$message({
              message: "请输入答案",
              type: 'warning'
            });
            return
          }else {
            answer.push(this.kong_value_list[i].content);
          }
        }
        
        // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)

        this.$emit("submitAnswer",{
          answer: answer,
          supletime: supletime
        })
      },
    }
}
</script>
<style  lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 100px 100px;
  margin-top: 50px;
  margin-bottom: 42px;
  .desc {
    display: flex;
    flex-wrap: wrap;
    line-height: 2.4;
    align-items: center;
    font-size: 20px;
    .indent {
      text-indent: 2rem;
    }
    .kong {
      display: inline-block;
      width: 35px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border: 1px solid #8D93A5;
      border-radius: 2px;
      font-size: 20px;
      margin-right: 4px;
      box-sizing: border-box;
    }
    .dis {
      background-color: #BBBEC9!important;
      color: #fff!important;
      border: none;
    }
    .err {
      border: 1px solid #FF0000!important;
      color: #FF0000!important;
    }
    .dui {
      border: 1px solid #00D315!important;
      color: #00D315!important;
    }
    .green {
      color: #00D315;
      margin-right: 10px;
    }
  }
}
</style>
