<template>
   <div class="comMain">
    <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="confirm"  v-if="!detail"></count-down>
    <div class="content">
      <div class="font-16">{{questionDetail.name}}</div>
      <div class="font-40 text-bold">{{questionDetail.sub_name}}</div>
      <div class="answer flex-center" v-if="!detail">
        <div class="btn m-r-50" :class="!correct?'btn_zi':''" @click="correct = false">NO</div>
        <div class="btn" :class="correct?'btn_zi':''" @click="correct = true">YES</div>
      </div>
      <div class="answer flex-center" v-else>
        <div class="btn m-r-50" :class="questionDetail.user_answer=='1'?'btn_zi':''">NO</div>
        <div class="btn" :class="questionDetail.user_answer=='0'?'btn_zi':''">YES</div>
      </div>
    </div>
   </div>
</template>
<script>
export default {
    props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      }
    },
    watch: {
      questionDetail(newVal, oldVal) {
        // console.log("666")
        if(this.$refs.countDownComponent) {
          this.$nextTick(() => {
            this.$refs.countDownComponent.initData();
          })
        }
      }
    },
   data() {
      return {
        correct: false,   //答案
        answer: '',
        timer: ''
      }
   },
    methods: {
      confirm() {
        this.answer = this.correct?0:1;

        // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)

        this.$emit("submitAnswer",{
          answer: this.answer,
          supletime: supletime
        })
      },
    }
}
</script>
<style  lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px 100px;
    margin-top: 40px;
    .answer {
      width: 100%;
      margin-top: 60px;
      .btn {
        width: 130px;
        height: 45px;
        line-height: 45px;
        border-radius: 2px;
        text-align: center;
        border: 1px solid #DDDFE4;
      }
      .btn_zi {
        border: 1px solid $color-title2!important;
        color: $color-title2;
      }
    }
  }
</style>
