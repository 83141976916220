<template>
   <div class="comMain">
      <count-down ref="countDownComponent" :time="questionDetail.audio_time" @confirm="confirm" v-if="!detail"></count-down>
      <div class="content">
         <div class="m-b-30 text-bold font-22">{{questionDetail.name}}</div>
         <div class="flex">
            <img :src="questionDetail.images" alt="" class="tu">
            <div>
              <el-input type="textarea" v-model="answer" placeholder="填写您的答案" v-if="!detail"></el-input>
              <el-input type="textarea" v-model="questionDetail.user_answer" placeholder="填写您的答案" :disabled="true" v-else></el-input>
              <block v-if="detail">
                <div class="text-bold m-t-20 m-r-30">参考答案</div>
                <div class="m-r-30" style="width:500px">
                  <div v-html="questionDetail.true_answer"></div>
                </div>
              </block>
            </div>
            
         </div>
      </div>
   </div>
</template>
<script>
export default {
   props: {
      questionDetail: {
          type: Object,
          default: function () {
              return {}
          }
      },
      detail: {
        type: Boolean,
        default: false,   //true是详情
      }
    },
   data() {
      return {
         answer: '',
      }
   },
   watch: {
      questionDetail(newVal, oldVal) {
        // console.log("666")
        if(this.$refs.countDownComponent) {
          this.$nextTick(() => {
            this.$refs.countDownComponent.initData();
          })
        }
      }
    },
   methods: {
      confirm() {
        if(!this.answer) {
          this.$message({
              message: '请输入答案',
              type: 'warning'
          });
          return;
        }

        // 获取答题所用时间(s)
        var remain_time = this.$refs.countDownComponent.remain_time;
        var total_time = this.questionDetail.audio_time;
        var supletime = total_time - remain_time;
        // console.log(supletime)

        this.$emit("submitAnswer",{
          answer: this.answer,
          supletime: supletime
        })
        this.answer = "";
      },
    }
}
</script>
<style  lang="scss" scoped>
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 30px;
    margin: 40px auto;
    .tu {
      width: 182px;
      height: 182px;
      margin-right: 30px;
    }
    ::v-deep .el-textarea__inner {
      border: 1px solid #BBBEC9;
      width: 500px;
      height: 183px;
      border-radius: 2px;
      padding: 10px;
      box-sizing: border-box;
    } 
  }
</style>
