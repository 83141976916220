const audio = new Audio();
audio.autoplay  = true;
export default {
	data() {
		return {
			curPlay:false,   //播放状态
      play_index: null,
      play_type: 1,  // 1英音   2美音   3其他题型音频
		}
	},
	beforeDestroy() {
		this.pausemp3()
	},
	onHide() {
		this.pausemp3()
	},
	onUnload() {
		this.pausemp3()
	},
	methods: {
		// 播放mp3
		playmp3(index,type,item){
      this.play_type = type;
			if(!this.curPlay){
        let src = "";
        if(this.play_type == 1) {
          src = item.en_soundmark_audio;
        }else if(this.play_type == 2) {
          src = item.a_soundmark_audio;
        }else {
          src = item.audio;
        }
        console.log("播放音频")
				audio.src = src;
				audio.play();
				this.curPlay = true;
			}else{				
				console.log("暂停音频")
				audio.pause();
				this.curPlay = false;
			}

			var that = this;
			//音频结束事件
			audio.addEventListener( 'ended', function(e) {
				that.curPlay = false;
				console.log('结束')
			})

			this.play_index = index;
		},
		// 暂停
		pausemp3(){
			audio.pause();
			this.curPlay = false;
			this.play_index = null;
		},
	},
	
}
